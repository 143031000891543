interface InTreeItem {
  id?: string
  parentId?: string
  name?: string
  route?: string
  authorityCode?: string
  status?: number
  children?: Array<object>
}

// 递归实现数组转树状结构
export function toTree(arr: Record<string, any>, parentId = '0') {
  function loop(parentId?: string) {
    const res: InTreeItem[] = []
    for (let i = 0; i < arr.length; i++) {
      const item: InTreeItem = arr[i]

      if (item.parentId !== parentId) {
        continue
      }

      item.children = loop(item.id)
      res.push(item)
    }
    return res
  }
  return loop(parentId)
}

// 两个数组对象进行合并去重
export function arrayDeduplication(
  arg0: Record<string, any>[],
  arg1: Record<string, any>[],
  arg2?: string,
) {
  const arrs = [...arg0, ...arg1]
  //根据id去重
  const mapRep = new Map() // 存储重复的
  const map = new Map()
  const k = arg2 || 'id'
  for (const item of arrs) {
    if (map.has(item[k])) {
      mapRep.set(item[k], item)
    } else if (!map.has(item[k])) {
      map.set(item[k], item)
    }
  }
  return [[...map.values()], [...mapRep.values()]] //把map中所有的值取出来放进数组
}

// 递归
export const flatFunc = (
  res: Record<string, any>,
  data: Record<string, any>[],
  grandRoute?: string,
) => {
  console.log('flatFunc == ', res, data)
  for (let i = 0; i < data.length; i++) {
    const k = grandRoute ? grandRoute + '/' + data[i].route : data[i].route
    res[k] = {
      title: data[i].title,
      grandRoute: grandRoute,
      code: data[i].authorityCode.map((v) => v.code),
    }
    if (!data[i].children) {
      return
    }

    flatFunc(res, data[i].children, data[i].route)
  }

  // 递归出口

  // res.push({
  //   id: data.id,
  //   name: data.name,
  //   pid: data.pid,
  //   type: data.type,
  //   checked: data.checked
  // })
  // // 递归处理子节点
  // for (let i = 0; i < data.childrenList.length; i++) {
  //   res[data.route] = flatFunc(res, data.childrenList[i])
  // }
  // return res
}
// 随机数
export function numericStr(size: number) {
  // parseInt
  // return (Math.random() * Math.pow(10, size)).toString()
  return parseInt((Math.random() * Math.pow(10, size)).toString()).toString()
}
