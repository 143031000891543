import { request } from '@/utils/service'
import type * as Table from './types/index'

/** 分页查询工作人员信息 */
export function getStaffPageList(params: Table.GetStaffPageListParams) {
  return request<Table.GetStaffPageListParamsResponse>({
    url: 'UserApi/Staff/GetStaffPageList',
    method: 'post',
    params,
  })
}

/** 保存工作人员 */
export function saveStaff(params: Object) {
  return request({
    url: 'UserApi/Staff/SaveStaff',
    method: 'post',
    params,
  })
}

/** 查询工作人员信息 */
export function getStaffUser(params: Object) {
  return request<Record<string, any>>({
    url: 'UserApi/Staff/GetStaffUser',
    method: 'get',
    params,
  })
}

/** 修改排班状态 */
export function updateStaffIsScheduling(params: Object) {
  return request({
    url: 'UserApi/Staff/UpdateStaffIsScheduling',
    method: 'put',
    params,
  })
}

/** 修改启用状态 */
export function updateStaffIsEnable(params: Object) {
  return request({
    url: 'UserApi/Staff/UpdateStaffIsEnable',
    method: 'put',
    params,
  })
}

/** 保存排班周期 */
export function saveStaffSchedulingWeek(params: Object) {
  return request({
    url: 'UserApi/Staff/SaveStaffSchedulingWeek',
    method: 'post',
    params,
  })
}

/** 查询医师排班时段 */
export function getStaffSchedulingWeekTime(params: Object) {
  return request<Record<string, any>[]>({
    url: 'UserApi/Staff/GetStaffSchedulingWeekTime',
    method: 'get',
    params,
  })
}

/** 保存排班时段 */
export function saveStaffSchedulingWeekTime(params: Object) {
  return request<Record<string, any>[]>({
    url: 'UserApi/Staff/SaveStaffSchedulingWeekTime',
    method: 'post',
    params,
  })
}

/** 分页查询患者档案 */
export function getPatientPageList(params: Object) {
  return request<Record<string, any>[]>({
    url: 'UserApi/Patient/GetPatientPageList',
    method: 'post',
    params,
  })
}

/** 保存患者档案 */
export function savePatient(params: Object) {
  return request<Record<string, any>[]>({
    url: 'UserApi/Patient/SavePatient',
    method: 'post',
    params,
  })
}

/** 查询患者档案 */
export function getPatient(params: Object) {
  return request<Record<string, any>[]>({
    url: 'UserApi/Patient/GetPatient',
    method: 'get',
    params,
  })
}

/** 删除患者档案(只删除关联信息，不删除单据等) */
export function deletePatient(params: Object) {
  return request<Record<string, any>[]>({
    url: 'UserApi/Patient/DeletePatient',
    method: 'delete',
    params,
  })
}

/** 查询角色列表 */
export function getRoleList(params?: Object) {
  return request<Record<string, any>[]>({
    url: 'UserApi/Role/GetRoleList',
    method: 'get',
    params,
  })
}
/** 保存角色 */
export function saveRole(params: Object) {
  return request({
    url: 'UserApi/Role/SaveRole',
    method: 'post',
    params,
  })
}
/** 保存角色授权 */
export function saveRoleAuthority(params?: Object) {
  return request({
    url: 'UserApi/Role/SaveRoleAuthority',
    method: 'post',
    params,
  })
}
/** 查询角色授权 */
export function getRoleAuthorityList(params?: Object) {
  return request<Record<string, any>[]>({
    url: 'UserApi/Role/GetRoleAuthorityList',
    method: 'get',
    params,
  })
}
/** 删除角色及相关数据 */
export function delRole(params?: Object) {
  return request<Record<string, any>[]>({
    url: 'UserApi/Role/DeleteRole',
    method: 'delete',
    params,
  })
}
/** 查询所有菜单 */
// export function getAllMenu(params?: Object) {
//   return request<Record<string, any>[]>({
//     url: 'UserApi/Menu/GetStaffMenu',
//     method: 'get',
//     params,
//   })
// }
/** 获取当前登录用户信息 */
export function getLoginUser(params?: Object) {
  return request<Record<string, any>>({
    url: 'UserApi/Login/GetLoginUser',
    method: 'get',
    params,
  })
}
/** 获取用户菜单 */
export function getStaffMenu(params?: Object) {
  return request<Record<string, any>>({
    url: 'UserApi/Menu/GetStaffMenu',
    method: 'get',
    params,
  })
}
/** 添加角色 */
export function saveStaffRole(params?: Object) {
  return request({
    url: 'UserApi/Staff/SaveStaffRole',
    method: 'post',
    params,
  })
}

/** 重置密码 */
export function resetPassword(params: Object) {
  return request({
    url: 'UserApi/User/ResetPassword',
    method: 'get',
    params,
  })
}

/** 修改密码 */
export function updatePassword(params: Object) {
  return request({
    url: 'UserApi/User/UpdatePassword',
    method: 'post',
    params,
  })
}

/** 查询诊所列表 */
export function getClinicPageList(params: Object) {
  return request({
    url: 'UserApi/Clinic/GetClinicPageList',
    method: 'post',
    params,
  })
}

/** 保存诊所信息 */
export function saveClinic(params: Object) {
  return request({
    url: 'UserApi/Clinic/SaveClinic',
    method: 'post',
    params,
  })
}

/** 获取用户列表 */
export function getUserPageList(params: Object) {
  return request({
    url: 'UserApi/User/GetUserPageList',
    method: 'post',
    params,
  })
}

/** 获取用户信息 */
export function getUser(params: Object) {
  return request({
    url: 'UserApi/User/GetUser',
    method: 'post',
    params,
  })
}

/** 保存用户信息 */
export function saveUser(params: Object) {
  return request({
    url: 'UserApi/User/SaveUser',
    method: 'post',
    params,
  })
}

/** 初始化诊所 */
export function initClinic(params: Object) {
  return request({
    url: 'UserApi/Clinic/InitClinic',
    method: 'get',
    params,
  })
}

/** 查询诊所详情 */
export function getClinic(params: Object) {
  return request({
    url: 'UserApi/Clinic/GetClinic',
    method: 'get',
    params,
  })
}

/** 查询所有菜单 */
export function getAllMenu(params: Object) {
  return request({
    url: 'UserApi/Menu/GetAllMenu',
    method: 'get',
    params,
  })
}

/** 查询解析列表 */
export function describeDomainRecords(params: Object) {
  return request({
    url: 'UserApi/Clinic/DescribeDomainRecords',
    method: 'get',
    params,
  })
}

/** 新增解析 */
export function addDomainRecord(params: Object) {
  return request({
    url: 'UserApi/Clinic/AddDomainRecord',
    method: 'get',
    params,
  })
}

/** 删除解析 */
export function deleteSubDomainRecords(params: Object) {
  return request({
    url: 'UserApi/Clinic/DeleteSubDomainRecords',
    method: 'get',
    params,
  })
}

/** 查询密钥列表 */
export function getOtherAuthorizeList(params: Object) {
  return request({
    url: 'UserApi/OtherAuthorize/GetOtherAuthorizeList',
    method: 'get',
    params,
  })
}

/** 修改启用状态 */
export function updateOtherAuthorizeIsEnable(params: Object) {
  return request({
    url: 'UserApi/OtherAuthorize/UpdateOtherAuthorizeIsEnable',
    method: 'post',
    params,
  })
}

/** 保存第三方密钥 */
export function saveOtherAuthorize(params: Object) {
  return request({
    url: 'UserApi/OtherAuthorize/SaveOtherAuthorize',
    method: 'post',
    params,
  })
}

/** 保存诊所配置(平台) */
export function saveConfigPlatform(params: Object) {
  return request({
    url: 'UserApi/Clinic/SaveConfigPlatform',
    method: 'post',
    params,
  })
}

/** 查询诊所配置 */
export function getConfig(params: Object) {
  return request({
    url: 'UserApi/Clinic/GetConfig',
    method: 'get',
    params,
  })
}

/** 查询支付渠道配置列表 */
export function payChannelConfigList(params: Object) {
  return request({
    url: 'UserApi/PayChannelConfig/PayChannelConfigList',
    method: 'get',
    params,
  })
}

/** 保存支付渠道配置(平台) */
export function savePayChannelConfig(params: Object) {
  return request({
    url: 'UserApi/PayChannelConfig/SavePayChannelConfig',
    method: 'post',
    params,
  })
}

/** 删除支付渠道配置 */
export function deletePayChannelConfig(params: Object) {
  return request({
    url: 'UserApi/PayChannelConfig/DeletePayChannelConfig',
    method: 'get',
    params,
  })
}
